import {
    Component,
    OnInit,
    OnDestroy,
    TemplateRef,
    Input,
    ViewChild,
    Inject,
    PLATFORM_ID
} from '@angular/core';
import { Product } from '../../interfaces/product';
//import { QuickviewService } from '../../services/quickview.service';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { UntypedFormControl } from '@angular/forms';
import { HttpClient } from '@angular/common/http';
//import { Product } from '../../interfaces/product';
//import { Subject } from 'rxjs';
//import { takeUntil } from 'rxjs/operators';
import { CartService } from '../../../shared/services/cart.service';
import { CartModalService } from '../../../shared/services/cartmodal.service';
import { Subscription } from 'rxjs';
import { RootService } from '../../../shared/services/root.service';
//import { AngularFireAnalytics } from '@angular/fire/compat/analytics';
import { CartItem } from '../../interfaces/cart-item';
import { RouterLink } from '@angular/router';
//import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { json } from 'stream/consumers';
import { isPlatformBrowser } from '@angular/common';
import { GTMService } from '../../services/gtm.service';

function getCircularReplacer() {
    const seen = new WeakSet();
    return (key, value) => {
        if (typeof value === 'object' && value !== null) {
            if (seen.has(value)) {
                return;
            }
            seen.add(value);
        }
        return value;
    };
}

interface ProductImage {
    id: string;
    url: string;
    active: boolean;
}

@Component({
    selector: 'app-cartmodal',
    standalone: true,
    imports: [RouterLink, FormsModule, CommonModule],
    providers: [CartModalService],
    templateUrl: './cartmodal.component.html',
    styleUrls: ['./cartmodal.component.scss']
})
export class CartModalComponent implements OnInit, OnDestroy {
    // private destroy$: Subject<void> = new Subject();

    // @ViewChild('modal', { read: TemplateRef }) template: TemplateRef<any>;

    modalRef: BsModalRef;

    images: ProductImage[] = [];
    //quantity: UntypedFormControl = new UntypedFormControl(1);
    // product: Product;
    showDiv = false;

    message: string;
    messages: string[];
    subscription: Subscription;
    addingToCart1 = false;
    addingToCart2 = false;
    aantalpak = 1;
    addedtocart1 = false;
    addedtocart2 = false;
    plakplint: any;
    folieplint: any;

    productname: string;

    productimg: string;
    productcount: number;
    combinatiecount: number;
    combinatietype: number;
    combinatiename: string;
    combinatieimg: string;
    productcategorie: number;

    @ViewChild('cartmodal', { read: TemplateRef }) cartmodal: TemplateRef<any>;

    constructor(
        @Inject(PLATFORM_ID) private platformId: any,

        private cart: CartService,
        private data: CartModalService,
        private modalService: BsModalService,
        public root: RootService,
        private http: HttpClient,
        private _dataLayerService: GTMService //private analytics: AngularFireAnalytics
    ) {} // private quickview: QuickviewService,

    ngOnInit(): void {
        this.subscription = this.data.currentMessageCM.subscribe((message) => {
            //alert(JSON.stringify(message, getCircularReplacer()));

            this.message = message;
            if (this.message != '0-0-0') {
                this.messages = message.split('-');

                //alert(JSON.stringify(message));

                this.productcount = Number(this.messages[0]);

                this.combinatiecount = Number(this.messages[1]);

                this.combinatietype = Number(this.messages[2]);
            }
        });

        // readonly quantity$: Observable<number> =
        // this.quantitySubject$.asObservable();

        // readonly onAdding$: Observable<Product> =
        // this.onAddingSubject$.asObservable();

        this.cart.onAdding$.subscribe((product) => {
            //alert(JSON.stringify(this.data, getCircularReplacer()));
            //alert(JSON.stringify(this.quantity, getCircularReplacer()));
            if (product.uitvoeringtype != 'extrawinkelmand') {
                //this.productcount = product.quantity;
                this.productname = product.name;
                this.productimg = product.images[0];
                this.combinatiename =
                    product.combinatieondervloernaam[this.combinatietype];
                this.combinatieimg =
                    product.combinatieondervloerimg[this.combinatietype];
                this.openModal();
                this.productcategorie = product.categorieid;
            }
        });
    }

    openModal() {
        this.modalRef = this.modalService.show(this.cartmodal);
    }

    onFormSubmit(formData: any) {
        if (formData.number1) {
            this.addedtocart1 = true;
            this.addingToCart1 = true;

            this.http
                .post(
                    'https://api.voordeelvloeren.nl/product/573.json?type=extrawinkelmand',
                    {}
                )
                .subscribe((response) => {
                    this.plakplint = response;
                    const number1 = parseInt(formData.number1, 10);
                    this.cart
                        .add(this.plakplint, number1, 0, 0, false)
                        .subscribe({
                            complete: () => (this.addingToCart1 = false)
                        });

                    if (isPlatformBrowser(this.platformId)) {
                        this._dataLayerService.logEvent(
                            'add_to_cart',
                            response['prijspak'] * number1,
                            [
                                {
                                    item_id: 'vdvl-' + response['id'],
                                    item_name: response['name'],
                                    currency: 'EUR',
                                    item_brand: response['merknaam'],
                                    item_category: response['categorienaam'],
                                    price: response['prijspak'],
                                    quantity: number1
                                }
                            ]
                        );
                    }
                });

            setTimeout(() => {
                this.addedtocart1 = false;
            }, 4000);
        }

        if (formData.number2) {
            this.addedtocart2 = true;
            this.addingToCart2 = true;

            this.http
                .post(
                    'https://api.voordeelvloeren.nl/product/1666.json?type=extrawinkelmand',
                    {}
                )
                .subscribe((response) => {
                    this.folieplint = response;
                    const number2 = parseInt(formData.number2, 10);
                    this.cart
                        .add(this.folieplint, number2, 0, 0, false)
                        .subscribe({
                            complete: () => (this.addingToCart2 = false)
                        });

                    if (isPlatformBrowser(this.platformId)) {
                        this._dataLayerService.logEvent(
                            'add_to_cart',
                            response['prijspak'] * number2,
                            [
                                {
                                    item_id: 'vdvl-' + response['id'],
                                    item_name: response['name'],
                                    currency: 'EUR',
                                    item_brand: response['merknaam'],
                                    item_category: response['categorienaam'],
                                    price: response['prijspak'],
                                    quantity: number2
                                }
                            ]
                        );
                    }
                });

            setTimeout(() => {
                this.addedtocart2 = false;
            }, 4000);
        }
    }

    addToCart(): void {
        //        this.addedtocart2 = true;
        //alert(JSON.stringify(this.folieplint));
        // if (!this.addingToCart && this.product && this.quantity.value > 0) {
        //     this.analytics.logEvent('add_to_cart', {
        //         currency: 'EUR',
        //         value: this.product.prijsm2,
        //         items: [
        //             {
        //                 item_id: 'vdd-' + this.product.id,
        //                 item_name: this.product.name,
        //                 currency: 'EUR',
        //                 item_brand: this.product.merknaam,
        //                 item_category: this.product.categorienaam,
        //                 price: this.product.prijsm2,
        //                 quantity: 1
        //             }
        //         ]
        //     });
        //     this.addingToCart = true;
        //     alert('hij is toegevoegd aan de winkelmand');
        //     // this.cart
        //     //     .add(this.product, this.aantalpak, 0, 0, false)
        //     //     .subscribe({ complete: () => (this.addingToCart = false) });
        // }
    }

    ngOnDestroy() {
        if (this.subscription) {
            this.subscription.unsubscribe();
        }

        this.addedtocart1 = false;
        this.addedtocart2 = false;
    }

    // clickWinkelmand

    // /bestellen

    //     modalRef.hide()

    // ngAfterViewInit(): void {
    //     this.quickview.show$
    //         .pipe(takeUntil(this.destroy$))
    //         .subscribe((product) => {
    //             if (this.modalRef) {
    //                 this.modalRef.hide();
    //             }

    //             this.product = product;
    //             this.modalRef = this.modalService.show(this.template, {
    //                 class: 'modal-dialog-centered modal-xl'
    //             });
    //         });
    // }

    // ngOnDestroy(): void {
    //     this.destroy$.next();
    //     this.destroy$.complete();
    // }
}
